import React from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import Routes from "./RouterPage";
import Students from "./pages/Students/Students";

// ReactDOM.render(
//     <App />,
//   document.getElementById('root')
// );
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* <React.StrictMode> */}
    <Routes />
    {/* <Students />  */}
    {/* <App />  */}
    {/* </React.StrictMode> */}
  </>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
