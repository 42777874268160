import React, { useState, useEffect } from "react";
import http from "../common/httpCommon";

const KEYS = {
  students: "students",
  studentId: "studentId",
};

export const getClassCollection = () => [
  { id: "1", title: "NURSERY" },
  { id: "2", title: "LKG" },
  { id: "3", title: "UKG" },
  { id: "4", title: "I" },
  { id: "5", title: "II" },
  { id: "6", title: "III" },
  { id: "7", title: "IV" },
  { id: "8", title: "V" },
  { id: "9", title: "VI" },
  { id: "10", title: "VII" },
  { id: "11", title: "VIII" },
  { id: "12", title: "IX" },
  { id: "13", title: "X" },
  { id: "14", title: "XI (Art)" },
  { id: "15", title: "XI (Comm.)" },
  { id: "16", title: "XI (Sci.)" },
  { id: "17", title: "XII (Art)" },
  { id: "18", title: "XII (Comm.)" },
  { id: "19", title: "XII (Sci.)" },
];

// export function insertStudent(data) {
//   //const url =`${baseUrl}`;
//   // const data1={
//   //     "sName": sname,
//   //     "data1": data1,
//   //     "data2": data2
//   //   }
//   http
//     .post("/ImageUpload", data)
//     .then((result) => {
//     })
//     .catch((error) => {
//       // toast.error('something wrong happened with server or network')
//       setNotify({
//         isOpen: true,
//         message: "Submitted Successfully",
//         type: "success",
//       });
//     });
// }

// export function insertStudent(data) {
//   console.log(data);
//   let students = getAllStudents();
//   data["id"] = generateStudentId();
//   students.push(data);
//   localStorage.setItem(KEYS.students, JSON.stringify(students));
// }

// export function updateStudent(data) {
//   let students = getAllStudents();
//   let recordIndex = students.findIndex((x) => x.id == data.id);
//   students[recordIndex] = { ...data };
//   localStorage.setItem(KEYS.students, JSON.stringify(students));
// }

// export function deleteStudent(id) {
//   let students = getAllStudents();
//   students = students.filter((x) => x.id != id);
//   localStorage.setItem(KEYS.students, JSON.stringify(students));
// }

export function generateStudentId() {
  if (localStorage.getItem(KEYS.studentId) == null)
    localStorage.setItem(KEYS.studentId, "0");
  var id = parseInt(localStorage.getItem(KEYS.studentId));
  localStorage.setItem(KEYS.studentId, (++id).toString());
  return id;
}

// export function getAllStudents() {
//   if (localStorage.getItem(KEYS.students) == null)
//     localStorage.setItem(KEYS.students, JSON.stringify([]));
//   let students = JSON.parse(localStorage.getItem(KEYS.students));
//   //map departmentID to department title
//   let sClasses = getClassCollection();
//   return students.map((x) => ({
//     ...x,
//     sClass: sClasses[x.sClassId - 1].title,
//   }));
// }
export function GetAllStudents() {
  //debugger;

  const [data, setData] = useState([]);
  //setData(getData());

  useEffect(() => {
    //setData();
    getData();
  }, []);
  const getData = () => {
    http
      .get("/StudentIDCardRecord")
      .then((result) => {
        // getallStudent = result.data;
        //console.log("getData: ", result.data);
        setData(result.data);
        //console.log("result.data:", getallStudent);
        // setNotify({
        //   isOpen: true,
        //   message: "Submitted Successfully",
        //   type: "success",
        // });
        //return result.data;
      })
      .catch((error) => {
        // setNotify({
        //   isOpen: true,
        //   message: "something wrong happened with server or network",
        //   type: "error",
        // });
        //return null;
        //return getallStudent;
      });
  };
}
