import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { InputLabel, FormHelperText, TextField } from "@material-ui/core";
import {
  Box,
  Typography,
  Button,
  ListItem,
  withStyles,
} from "@material-ui/core";

import UploadService from "../../services/uploadImageService";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    //this.onTrigger = this.onTrigger.bind(this);

    this.state = {
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,

      message: "",
      //response: "",
      isError: false,
      // imageInfos: [],
      imageInfos: "",
      uploadImage: false,
      // error: error,
    };
  }

  // componentDidMount() {
  //   UploadService.getFiles().then((response) => {
  //     this.setState({
  //       imageInfos: response.data,
  //     });
  //   });
  // }
  onPassToParent(obj) {
    this.props.handleImgUpload(obj);
    //obj.preventDefault();
  }
  onFileSelectError(error) {
    this.props.onImageSelectError(error);
  }
  selectFile(event) {
    if (event.target.files[0] === undefined) return;
    this.setState({
      previewImage: null,
      currentFile: null,
      imageInfos: { srPhoto: "", srPhotoURL: "" },
    });
    this.onPassToParent(this.state.imageInfos);
    // value="";
    var fileName = event.target.files[0].name;
    if (event.target.files[0].size > 8388608) {
      //this.onPassToParent("");
      // this.onPassToParent("");

      alert("Please select the image size less than 8MB");
      return;
      // else onFileSelectSuccess(file);
    }
    if (
      fileName.split(".").pop() === "jpeg" ||
      fileName.split(".").pop() === "jpg"
    ) {
    } else {
      alert("Please select jpg or jpeg image");
      return;
    }
    this.setState(
      {
        currentFile: event.target.files[0],
        previewImage: URL.createObjectURL(event.target.files[0]),
        progress: 0,
        //message: ""
        imageInfos: "",
        message: JSON.stringify(event.target.files[0]),
        uploadImage: false,
      },
      () => {}
    );
  }

  upload() {
    this.setState({
      progress: 0,
    });

    UploadService.upload(
      this.state.currentFile,
      this.props.datascId,
      (event) => {
        this.setState({ imageInfos: "" });
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });

        if (this.state.currentFile.size > 2097152) {
        }
      }
    )
      .then((response) => {
        this.setState(
          {
            // message: response.data.message,
            message: JSON.stringify(response.data.post.srPhoto),
            imageInfos: response.data.post,
            isError: false,
            uploadImage: true,
          },
          () => {
            this.onPassToParent(this.state.imageInfos);
            this.setState({ currentFile: undefined });
          }
        );

        // return UploadService.getFiles();
      })
      // .then((files) => {
      //   this.setState({
      //     imageInfos: files.data,
      //   });
      // })
      .catch((err) => {
        this.setState({
          progress: 0,
          //message: "Could not upload the image!",
          message: JSON.stringify(err),
          currentFile: undefined,
          isError: true,
          uploadImage: false,
          imageInfos: "",
        });
      });
  }

  render() {
    const {
      currentFile,
      previewImage,
      progress,
      imageInfos,
      message,
      uploadImage,
      isError,
    } = this.state;

    let {
      name,
      label,
      value,
      error = null,
      onChange,
      datascId,
      ...other
    } = this.props;
    return (
      // <div className="mg20">
      <div className="MuiFormControl-root">
        <label htmlFor="btn-upload">
          <input
            id="btn-upload"
            name="btn-upload"
            style={{ display: "none" }}
            type="file"
            accept="image/jpg"
            onChange={this.selectFile}
          />
          <Button className="btn-choose" variant="outlined" component="span">
            Choose Image
          </Button>
        </label>
        <div className="file-name">{currentFile ? currentFile.name : null}</div>
        <Button
          className="btn-upload"
          color="primary"
          variant="contained"
          component="span"
          disabled={!currentFile}
          onClick={this.upload}
          //value={value}
          // {...other}
          // {...(error && { error: true, helperText: error })}
        >
          Upload
        </Button>
        <TextField
          className="hide20"
          variant="outlined"
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          {...other}
          {...(error && { error: true, helperText: error })}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
        {currentFile && (
          <Box className="my20" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <BorderLinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`${progress}%`}</Typography>
            </Box>
          </Box>
        )}

        {previewImage && (
          <div>
            <img
              className="preview my20"
              src={previewImage}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        )}

        {uploadImage && (
          <Typography
            variant="subtitle2"
            className={`upload-message ${isError ? "error" : ""}`}
          >
            image uploded successfully:- {message}
          </Typography>
        )}
        {/* <TextField
          variant="outlined"
          label={label}
          name={name}
          value={value && value}
          onChange={onChange}
          {...other}
          {...(error && { error: true, helperText: error })}
        /> */}
        {/* <InputLabel
          value={imageInfos}
          {...(error && { error: true, helperText: error })}
        >
          {imageInfos}
          {error && error}
          
        </InputLabel> */}
        {/* {error && <FormHelperText>{error}</FormHelperText>} */}
        {/* <Typography variant="h6" className="list-header">
          List of Images
        </Typography>
        <ul className="list-group">
          {imageInfos &&
            imageInfos.map((image, index) => (
              <ListItem divider key={index}>
                <img
                  src={image.url}
                  alt={image.name}
                  height="80px"
                  className="mr20"
                />
                <a href={image.url}>{image.name}</a>
              </ListItem>
            ))}
        </ul> */}
      </div>
    );
  }
}
