import http from "../common/httpCommon";

class UploadFilesService {
  upload(file, scID, onUploadProgress) {
    let formData = new FormData();
    formData.append("UserId", scID);
    formData.append("Description", "abc");
    formData.append("Image", file);
    formData.append("ImagePath", "");

    return http.post("/ImageUpload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  // getFiles() {
  //   return http.get("/files");
  // }
}

export default new UploadFilesService();
