import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";
import * as studentService from "../../services/studentService";
import ImageUpload from "../../components/controls/ImageUpload";
import { format } from "date-fns";
import { SentimentDissatisfiedSharp } from "@material-ui/icons";

const initialFValues = {
  srName: "",
  id: 0,
  scID: "",
  srClass: "",
  srFatherName: "",
  srAddress: "",
  srMobile: "",
  srData1: "",
  srData2: "",
  srData3: "",
  srPhotoURL: "",
  srPhoto: "",
};

export default function StudentForm(props) {
  const { addOrEdit, recordForEdit, data } = props;
  const [imageInfo, setImageInfo] = useState({
    srPhoto: "",
    srPhotoURL: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("srName" in fieldValues)
      temp.srName = fieldValues.srName ? "" : "This field is required.";
    if ("srAddress" in fieldValues)
      temp.srAddress = fieldValues.srAddress ? "" : "This field is required.";
    if ("srMobile" in fieldValues)
      temp.srMobile =
        fieldValues.srMobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("srFatherName" in fieldValues)
      temp.srFatherName =
        fieldValues.srFatherName.length != 0 ? "" : "This field is required.";
    if ("srClass" in fieldValues)
      temp.srClass =
        fieldValues.srClass.length != 0 ? "" : "This field is required.";
    if (data.data.data1) {
      if ("srData1" in fieldValues)
        temp.srData1 =
          fieldValues.srData1.length != 0 ? "" : "This field is required.";
    }
    if (data.data.data2) {
      if ("srData2" in fieldValues)
        temp.srData2 =
          fieldValues.srData2.length != 0 ? "" : "This field is required.";
    }
    if (data.data.data3) {
      if ("srData3" in fieldValues)
        temp.srData3 =
          fieldValues.srData3.length != 0 ? "" : "This field is required.";
    }
    if ("srPhotoURL" in fieldValues) {
      temp.srPhotoURL =
        fieldValues.srPhotoURL.length != 0
          ? ""
          : "Please Select and Upload image(max 2MB)(.jpg or .jpeg.)";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) {
      return Object.values(temp).every((x) => x == "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      values.srPhoto = imageInfo.srPhoto;
      values.scID = props.data.data.id;
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  //get image Information(imagePath and imageName) of Uploaded Image from ImageUpload components

  const handleImgUpload = (obj) => {
    // 👇️ take the parameter passed from the Child component
    setImageInfo((imageInfo) => ({ ...imageInfo, ...obj }));

    // return;
  };
  // const handleImgError = (error) => {
  //   //{...errors}=error;
  // };
  // const inputChangeEvent = (e) => {
  //   handleInputChange(e);
  // };
  useEffect(() => {}, [imageInfo]);
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            name="srName"
            label="Student Name"
            value={values.srName}
            onChange={handleInputChange}
            error={errors.srName}
          />
          <Controls.Select
            name="srClass"
            label="Select Class"
            value={values.srClass}
            onChange={handleInputChange}
            options={studentService.getClassCollection()}
            error={errors.srClass}
          />
          <Controls.Input
            name="srFatherName"
            label="Father Name"
            value={values.srFatherName}
            onChange={handleInputChange}
            error={errors.srFatherName}
          />
          {/* <Controls.DatePicker                      
                        name="DOB"
                        label="D.O.B"
                        format="dd/MM/yyyy"
                        value={values.DOB}                     
                        onChange={handleInputChange}
                        error={errors.DOB}
                    /> */}
          <Controls.Input
            label="Address"
            name="srAddress"
            value={values.srAddress}
            onChange={handleInputChange}
            error={errors.srAddress}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            label="Mobile"
            name="srMobile"
            value={values.srMobile}
            onChange={handleInputChange}
            error={errors.srMobile}
          />
          {data.data.data1 && (
            <>
              <Controls.Input
                label={data.data.data1}
                name="srData1"
                value={values.srData1}
                onChange={handleInputChange}
                error={errors.srData1}
              />
            </>
          )}
          {data.data.data2 && (
            <>
              <Controls.Input
                label={data.data.data2}
                name="srData2"
                value={values.srData2}
                onChange={handleInputChange}
                error={errors.srData2}
              />
            </>
          )}
          {data.data.data3 && (
            <>
              <Controls.Input
                label={data.data.data3}
                name="srData3"
                value={values.srData3}
                onChange={handleInputChange}
                error={errors.srData3}
              />
            </>
          )}

          <Controls.ImageUpload
            label="ImageInfo"
            name="imageInfo"
            value={(values.srPhotoURL = imageInfo.srPhotoURL)}
            //onSubmit={handleOnClickImageUpload}
            error={errors.srPhotoURL}
            onChange={handleInputChange}
            handleImgUpload={handleImgUpload}
            datascId={data.data.id}
            imageUpload
          />
          {/* <Controls.ImageUpload handleImgUpload={handleImgUpload} value="{values.imageInfo}"/> */}
          {/* <Controls.RadioGroup
                        name="gender"
                        label="Gender"
                        value={values.gender}
                        onChange={handleInputChange}
                        items={genderItems}
                    />
                 
                    <Controls.Checkbox
                        name="isPermanent"
                        label="Permanent Student"
                        value={values.isPermanent}
                        onChange={handleInputChange}
                    /> */}
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Reset" color="default" onClick={resetForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
