import React, { useState, useEffect } from "react";
import http from "../../common/httpCommon";
import axios from "axios";
import { Paper, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Students from "./Students";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
}));

export default function CheckInvalidLink() {
  const params = useParams();
  const classes = useStyles();
  const [temp, setTemp] = useState(0);
  useEffect(() => {}, [temp]);
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    http
      .get("/StudentIDCard/GetValidSchool/" + params.slink)
      .then((result) => {
        setData(result.data);
        setTemp(2);
      })
      .catch((error) => {
        setTemp(1);
        //toast.error('something wrong happened with server or network')
        // return (
        //   <Paper className={classes.pageContent} style={{ overflowX: "auto" }}>
        //     Invalid Link
        //   </Paper>
        // );
      });
  };
  if (data && data.length <= 0 && temp > 0) {
    return (
      <Paper className={classes.pageContent} style={{ overflowX: "auto" }}>
        Invalid Link
      </Paper>
    );
  } else if (temp > 1) {
    return <Students data={data} />;
  }
}
