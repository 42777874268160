import React from "react";  
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Students from "./pages/Students/Students";
import App from "./App/App";



export default function RouterPage(){
    return(
        <Router>
            <Routes>
            <Route path='/' element={<App/>}/>
                
            <Route path='/:slink' element={<App/>}/>
            </Routes>
        </Router>
    )
}